<template>
  <div>
    <h1>Settings</h1>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>

</style>
